import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { autenticacaoGuard } from './core/guards/autenticacao.guard';
import { rotaPublicaGuard } from './core/guards/rota-publica.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login'
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule),
        canActivate: [rotaPublicaGuard]
    },
    {
        path: 'cadastro',
        loadChildren: () =>
            import('./pages/cadastro/cadastro.module').then((m) => m.CadastroModule),
        canActivate: [rotaPublicaGuard]
    },
    {
        path: 'check-autenticacao',
        loadChildren: () =>
            import('./pages/check-autenticacao/check-autenticacao.module').then(
                (m) => m.CheckAutenticacaoModule
            ),
        canActivate: [rotaPublicaGuard]
    },
    {
        path: 'redefinir-senha/:token',
        loadChildren: () =>
            import('./pages/redefinir-senha/redefinir-senha.module').then(
                (m) => m.RedefinirSenhaModule
            ),
        canActivate: [rotaPublicaGuard]
    },
    {
        path: 'recuperar-senha',
        loadChildren: () =>
            import('./pages/recuperar-senha/recuperar-senha.module').then(
                (m) => m.RecuperarSenhaModule
            ),
        canActivate: [rotaPublicaGuard]
    },
    {
        path: 'check-mfa',
        loadChildren: () =>
            import('./pages/check-mfa/check-mfa.module').then((m) => m.CheckMfaModule),
        canActivate: [rotaPublicaGuard]
    },
    {
        path: 'painel-aplicacoes',
        canActivate: [autenticacaoGuard],
        loadChildren: () =>
            import('./pages/painel-aplicacoes/painel-aplicacoes.module').then(
                (m) => m.PainelAplicacoesModule
            )
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
