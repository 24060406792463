import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from '@sbt-suite/components';
import { EMPTY, Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private apisRequiringAuth = ['/api/v1/aplicacoes'];

    constructor(private _router: Router, private _cookie: CookieService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const token = this._cookie.get('access_token');
        const isUrlAppToken = request.url.includes('/login/api/v1/login');

        // Verifica se a URL da requisição está na lista de APIs que exigem autenticação
        const requiresAuth = this.apisRequiringAuth.some((apiUrl: string) =>
            request.url.includes(apiUrl)
        );

        if (!token && requiresAuth) {
            this._router.navigateByUrl('/login');
            return EMPTY;
        } else if (token && !isUrlAppToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }

        return next.handle(request);
    }
}
