<h1 mat-dialog-title>Minhas Solicitações</h1>
<div mat-dialog-content>

    <mat-spinner *ngIf="loading"></mat-spinner>
    <mat-list *ngIf="!loading">
        <ng-container *ngFor="let item of list">
            <mat-list-item>
                <div matListItemTitle>
                    {{ item.aplicacao || '-' }}
                    <span class="status-label" [ngStyle]="{background: item.statusLabel?.color}">{{
                        item.statusLabel?.label }}</span>
                </div>
                <div matListItemLine>{{ item.dataCriacao || '-' }}</div>
            </mat-list-item>
            <mat-divider></mat-divider>
        </ng-container>
    </mat-list>

    <div class="mt-16" *ngIf="!loading">
        <p>*Obs: Após a liberação do acesso, será necessário deslogar e logar novamente para que as alterações
            entrem em
            vigor.
        </p>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close="">FECHAR</button>
</div>