import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getHeadersFormUrlEncoded } from '@sbt-suite/components';
import { lastValueFrom } from 'rxjs';
import { buildUrl } from 'src/app/shared/functions';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '..';
import { ICredenciaisUsuario, IResponseAuthLogin } from '../../models';
import { RsaService } from '../rsa/rsa.service';
import { IAplicacaoAPI } from './../../models/usuario.model';

@Injectable({
    providedIn: 'root'
})
export class UsuarioService {
    URL_BASE = environment.URL_BASE;
    PORT_LOGIN = environment.PORT_LOGIN;
    PORT_CORE = environment.PORT_CORE;

    constructor(
        private http: HttpClient,
        private rsa: RsaService,
        private localStorageService: LocalStorageService
    ) {
        if (environment.MODE == 'dev' || environment.MODE == 'prod') {
            this.URL_BASE += '/login';
        }
    }

    //TODO: atualizar tipagem de retorno quando tiver esses dados do endpoint
    async cadastroContaExterno(credenciais: ICredenciaisUsuario): Promise<IResponseAuthLogin> {
        const { nonceToken, senhaEncriptada } = await this.rsa.gerarNonceTokenESenha(credenciais);
        const url = this.URL_BASE.replace('/login', '/core');
        return lastValueFrom(
            this.http.post<IResponseAuthLogin>(buildUrl(url, '/api/v1/usuario', this.PORT_CORE), {
                nonceToken,
                senhaEncriptada,
                usuario: credenciais.email
            })
        );
    }

    //TODO: atualizar tipagem de retorno quando tiver esses dados do endpoint
    async redefinirSenha(credenciais: ICredenciaisUsuario, token: string) {
        const { nonceToken, senhaEncriptada } = await this.rsa.gerarNonceTokenESenha(credenciais);
        const url = this.URL_BASE.replace('/login', '/core');
        //TODO: ver api para redefinir senha
        return lastValueFrom(
            this.http.put(
                buildUrl(url, `/api/v1/usuario/${credenciais.email}/senha`, this.PORT_CORE),
                {
                    nonceToken: nonceToken,
                    usuario: credenciais.email,
                    senhaEncriptada: senhaEncriptada,
                    token: token
                }
            )
        );
    }

    //TODO: atualizar tipagem de retorno quando tiver esses dados do endpoint
    async recuperarSenha(email: string) {
        //TODO: ver parametros para api de recuperar senha
        const params = new HttpParams().set('email', email);
        const url = this.URL_BASE.replace('/login', '/core');
        //TODO: ver api para recuperar senha
        return lastValueFrom(
            this.http.post(
                buildUrl(url, `/api/v1/usuario/${email}/senha`, this.PORT_CORE),
                params.toString(),
                {
                    headers: getHeadersFormUrlEncoded()
                }
            )
        );
    }

    async listarAplicacoes(): Promise<IAplicacaoAPI[]> {
        return await lastValueFrom(
            this.http.get<IAplicacaoAPI[]>(
                buildUrl(this.URL_BASE, '/api/v1/aplicacoes', this.PORT_CORE)
            )
        );
    }
}
