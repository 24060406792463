import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { INavBar, PermissoesService } from '@sbt-suite/components';
import { environment } from 'src/environments/environment';
import { AutenticacaoService } from './core/services';
import { LocalStorageService } from './core/services/localStorage/local-storage.service';
import { ModalSolicitacoesComponent } from './shared/components/modal-solicitacoes/modal-solicitacoes.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    MODE = environment.MODE;
    URL_LOGIN = environment.URL_LOGIN;
    autenticado!: boolean;
    openedMenu: boolean = true;

    menuOptions: INavBar.MenuOption[] = [
        {
            label: 'Minhas Solicitações',
            action: () => this.openSolicitacoes()
        }
    ];

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private autenticacaoService: AutenticacaoService,
        private router: Router,
        private localStorageService: LocalStorageService,
        private permissoesService: PermissoesService,
        private _activatedRoute: ActivatedRoute,
        private _dialog: MatDialog
    ) {
        permissoesService.registrarPermissoes(['default-roles-master']);
    }

    ngOnInit() {
        this.checkUsuarioAutenticado();
    }

    checkUsuarioAutenticado() {
        this.autenticacaoService.checkUsuarioAutenticado().subscribe((autenticado) => {
            this.autenticado = autenticado;
            this.setDarkTheme(autenticado);
        });
    }

    openSolicitacoes() {
        const dialog = this._dialog.open(ModalSolicitacoesComponent, {
            maxWidth: '450px'
        });
    }

    async logout(event: boolean) {
        if (event) {
            //TODO: LOGOUT POR API FICARÁ PARA OUTRA FASE
            // this.autenticacaoService.getServiceSelecionado();
            // const serviceAutenticacao = this.autenticacaoService.getServiceAutenticacao();
            try {
                // await serviceAutenticacao.logout();
                this.autenticacaoService.clearCookie();
                this.router.navigateByUrl('/login');
                this.autenticacaoService.setUsuarioAutenticado(false);
                this.localStorageService.clear();
            } catch (error) {
                console.error(error);
            }
        }
    }

    setDarkTheme(autenticado: boolean) {
        const body = this.document.body;
        if (autenticado) {
            body.classList.add('dark-theme');
            body.classList.add('mat-app-background');
        } else {
            body.classList.remove('dark-theme');
            body.classList.add('mat-app-background');
        }
    }
}
