import { HttpClient, HttpParams } from '@angular/common/http';
import { getHeadersFormUrlEncoded } from '@sbt-suite/components';
import { lastValueFrom } from 'rxjs';
import { buildUrl } from 'src/app/shared/functions';
import { environment } from 'src/environments/environment';
import { RsaService } from '..';
import { GRANT_TYPE_PASSWORD } from '../../constants/autenticacao';
import { IAutenticacaoService, ICredenciaisUsuario, IResponseAuthLogin } from '../../models';

export class UsuarioExternoAutenticacaoService implements IAutenticacaoService {
    URL_BASE = environment.URL_BASE;
    PORT_LOGIN = environment.PORT_LOGIN;
    PORT_CORE = environment.PORT_CORE;
    AZURE_CLIENT_ID = environment.AZURE_CLIENT_ID;
    AZURE_REDIRECT_URI = environment.AZURE_REDIRECT_URI;
    AZURE_CLIENT_SECRET = environment.AZURE_CLIENT_SECRET;
    URL_OPEN_ID = environment.URL_OPEN_ID;

    constructor(private http: HttpClient, private rsa: RsaService) {
        if (environment.MODE == 'dev' || environment.MODE == 'prod') {
            this.URL_BASE += '/login';
        }
    }

    async login(params: any): Promise<void> {
        await this._loginExterno(params);
    }

    logout(): Promise<any> {
        return lastValueFrom(
            this.http.post(buildUrl(this.URL_BASE, '/api/v1/logout', this.PORT_LOGIN), {})
        );
    }

    private async _loginExterno(credenciais: ICredenciaisUsuario): Promise<IResponseAuthLogin> {
        const { nonceToken, senhaEncriptada } = await this.rsa.gerarNonceTokenESenha(credenciais);

        const params = new HttpParams()

            .set('username', credenciais.email)
            .set('password', senhaEncriptada)
            .set('nonceToken', nonceToken)
            .set('grant_type', GRANT_TYPE_PASSWORD);

        return lastValueFrom(
            this.http.post<IResponseAuthLogin>(
                buildUrl(this.URL_BASE, '/api/v1/login', this.PORT_CORE),
                params.toString(),
                {
                    headers: getHeadersFormUrlEncoded()
                }
            )
        );
    }
}
