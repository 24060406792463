import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { map } from 'rxjs';
import { AutenticacaoService } from '../services';

export const autenticacaoGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const router = inject(Router);
    const autenticacaoService = inject(AutenticacaoService);

    return autenticacaoService.checkUsuarioAutenticado().pipe(
        map((autenticado) => {
            if (autenticado && autenticacaoService.checkAcessToken()) return true;

            router.navigateByUrl('/login');
            return false;
        })
    );
};
