export const LINKS_APLICACOES: { [key: string]: { [key: string]: string } } = {
    dev: {
        core: 'https://core-h.tvsbt.com.br',
        discoteca: 'https://discoteca-h.tvsbt.com.br',
        ['acompanhamento-casos']: 'https://acompanhamentocasos-h.tvsbt.com.br',
        ['diaria-adicional']: 'https://diariaadicional-h.tvsbt.com.br',
        ['lista-telefonica']: 'https://listatelefonica-h.tvsbt.com.br'
    },
    local: {
        core: 'https://core-h.tvsbt.com.br',
        discoteca: 'https://discoteca-h.tvsbt.com.br',
        ['acompanhamento-casos']: 'https://acompanhamentocasos-h.tvsbt.com.br',
        ['diaria-adicional']: 'https://diariaadicional-h.tvsbt.com.br',
        ['lista-telefonica']: 'https://listatelefonica-h.tvsbt.com.br'
    }
};
