import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs';
import { AutenticacaoService, LocalStorageService } from '../services';

export const rotaPublicaGuard: CanActivateFn = (route, state) => {
    const router = inject(Router);
    const autenticacaoService = inject(AutenticacaoService);
    const localStorageService = inject(LocalStorageService);

    function checkRouteRedirectingBack() {
        const urlParams = new URLSearchParams(window.location.search);
        const redirectBack = urlParams.get('redirectBack');
        if (redirectBack) localStorageService.setItem('redirectBack', redirectBack);
    }

    return autenticacaoService.checkUsuarioAutenticado().pipe(
        map((autenticado) => {
            checkRouteRedirectingBack();

            if (autenticado && autenticacaoService.checkAcessToken()) {
                router.navigateByUrl('/painel-aplicacoes');
                return false;
            }

            return true;
        })
    );
};
