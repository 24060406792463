import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ISolicitacaoAcesso } from '../../models';

@Injectable({
    providedIn: 'root'
})
export class SolicitacaoAcessoService {
    URL_BASE = environment.URL_BASE;

    constructor(private _http: HttpClient) {
        if (environment.MODE == 'dev' || environment.MODE == 'prod') {
            this.URL_BASE += '/login';
        }
    }

    getGrupo(aplicacao: string): Promise<ISolicitacaoAcesso.Grupo[]> {
        return lastValueFrom(
            this._http.get<ISolicitacaoAcesso.Grupo[]>(`${this.URL_BASE}/api/v1/grupo/${aplicacao}`)
        );
    }

    solicitarAcesso(
        body: ISolicitacaoAcesso.SolicitacaoBody
    ): Promise<ISolicitacaoAcesso.SolicitacaoBody> {
        return lastValueFrom(
            this._http.post<ISolicitacaoAcesso.SolicitacaoBody>(
                `${this.URL_BASE}/api/v1/acesso`,
                body
            )
        );
    }

    getListaSolicitacaoAcesso(): Promise<ISolicitacaoAcesso.ListaSolicitacao[]> {
        return lastValueFrom(
            this._http.get<ISolicitacaoAcesso.ListaSolicitacao[]>(`${this.URL_BASE}/api/v1/acesso`)
        );
    }
}
