import { STATUS_WORKFLOW } from '../core/models';

export function buildUrl(base: string, path: string, port?: string): string {
    return `${base}${port ? `:${port}` : ''}${path}`;
}

export const mapStatusWorkflow = (status: STATUS_WORKFLOW): { label: string; color: string } => {
    const mapStatus = {
        CANCELADO: { label: 'Cancelado', color: '#616161' },
        APROVADO: { label: 'Aprovado', color: '#4caf50' },
        FINALIZADO: { label: 'Finalizado', color: '#4caf50' },
        REPROVADO: { label: 'Reprovado', color: '#f44336' },
        WORKFLOW_SOLICITADO: { label: 'Solicitação enviada', color: '#ff9800' },
        WORKFLOW_VALIDACAO: { label: 'Aguardando Validação', color: '#ff9800' }
    };

    return mapStatus[status] || '';
};
