import { Component } from '@angular/core';
import { AlertMessageService, formatDateForFrontend } from '@sbt-suite/components';
import { ISolicitacaoAcesso, STATUS_WORKFLOW } from 'src/app/core/models';
import { SolicitacaoAcessoService } from 'src/app/core/services/solicitacao-acesso/solicitacao-acesso.service';
import { mapStatusWorkflow } from '../../functions';

@Component({
    selector: 'app-modal-solicitacoes',
    templateUrl: './modal-solicitacoes.component.html',
    styleUrls: ['./modal-solicitacoes.component.scss']
})
export class ModalSolicitacoesComponent {
    loading: boolean = true;
    list: ISolicitacaoAcesso.ListaSolicitacao[] = [];

    constructor(
        private _solicitacaoAcesso: SolicitacaoAcessoService,
        private _alerts: AlertMessageService
    ) {
        this.getList();
    }

    async getList() {
        try {
            const response = await this._solicitacaoAcesso.getListaSolicitacaoAcesso();
            this.deserialize(response);
        } catch (error: any) {
            console.error(error?.error?.message);
            this._alerts.mostrarMensagemErroHttp(error?.error?.message);
        } finally {
            this.loading = false;
        }
    }

    deserialize(data: ISolicitacaoAcesso.ListaSolicitacao[]) {
        const deserialized = data.map((item) => {
            item.dataCriacao = formatDateForFrontend(item.dataCriacao, 'DD-MM-YYYY') as string;
            item.statusLabel = mapStatusWorkflow(item.status as STATUS_WORKFLOW);
            return item;
        });
        this.list = deserialized;
    }
}
