import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ModalSolicitacoesComponent } from './modal-solicitacoes.component';

@NgModule({
    declarations: [ModalSolicitacoesComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatListModule,
        MatProgressSpinnerModule
    ]
})
export class ModalSolicitacoesModule {}
